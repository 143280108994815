<template>
  <v-dialog
    v-model="showDialogSetPrice" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card class="linerp-layout">
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="設定加價金額"/>
        <v-btn @click="handleDialogSetPriceSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container class="my-2">
        <v-row class="px-3 pb-2">
          <v-col cols="2" class="py-0 px-0 d-flex justify-start align-center font-weight-bold">規格</v-col>
          <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center font-weight-bold">單價</v-col>
          <v-col cols="1" class="py-0 px-0"></v-col>
          <v-col cols="4" class="py-0 px-0 d-flex justify-center align-center font-weight-bold">規格加價</v-col>
          <v-col cols="1" class="py-0 px-0"></v-col>
          <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center font-weight-bold">總單價</v-col>
        </v-row>
      </v-container>
      <template v-for="(priceV, priceI) in generate_spec_price">
        <v-container v-if="priceV.groupItems.length > 0" :key="priceI" class="my-2">
          <v-row v-if="priceV.groupName" class="px-3 pb-2">
            <v-col class="py-0 px-0 d-flex justify-start align-center font-weight-bold">
              <div>{{ priceV.groupName }}</div>
            </v-col>
          </v-row>
          <v-row v-for="(itemV, itemI) in priceV.groupItems" :key="itemI" class="px-3 pb-2">
            <v-col cols="2" class="py-0 px-0 d-flex justify-start align-center" style="word-break: break-all;">{{ itemV.spec2 || itemV.spec1 }}</v-col>
            <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center" style="word-break: break-all;">{{ calculateResult }}</v-col>
            <v-col cols="1" class="py-0 px-0 d-flex justify-center align-center">+</v-col>
            <v-col cols="4" class="py-0 px-0 d-flex justify-center align-center">
              <v-text-field
                  dense
                  outlined
                  maxlength="10"
                  counter
                  placeholder="請輸入加價"
                  hide-details
                  class=""
                  v-model.number="itemV.add_price"
                  rounded
                  style="font-size: 14px;"
                  type="number"
                ></v-text-field>
            </v-col>
            <v-col cols="1" class="py-0 px-0 d-flex justify-center align-center">=</v-col>
            <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center" style="word-break: break-all;">{{ Number(calculateResult) + (Number(itemV.add_price) || 0) }}</v-col>
          </v-row>
        </v-container>
      </template>
      <v-container>
        <v-btn color="primary" small width="100%" @click="handleDialogSetPriceFinish(generate_spec_price)">
          設定完成
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialogSetPrice: {
      type: Boolean,
      required: true,
    },
    handleDialogSetPriceSwitch: {
      type: Function,
      required: true,
    },
    handleDialogSetPriceFinish: {
      type: Function,
      required: true,
    },
    specs: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    spec_price: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    generate_spec_price: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    calculateResult: {
      type: Number,
      required: true,
      default: () => {
        return 0
      }
    }
  },
  data() {
    return {
      
    }
  },
  watch: {
    showDialogSetPrice: {
      deep: true,
      handler(newArr, oldArr) {
        if(window.eagleLodash.isEqual(newArr, oldArr)) return
        if(newArr) this.updateGenerateData()
      },
    },
  },
  
  mounted(){
    this.updateGenerateData()
  },
  methods: {
    updateGenerateData(){
      const level1Specs = this.specs.find(item => item.level === 1)?.tags || [];
      const level2Specs = this.specs.find(item => item.level === 2)?.tags || [];
      let generate_spec_price = []
      if (level2Specs.length > 0) {
        generate_spec_price = level1Specs.map(spec1 => ({
          groupName: spec1.name,
          groupItems: level2Specs.map(spec2 => {
            const apiData = this.spec_price.find(
              apiSpec => {
                return apiSpec.spec1_id == spec1.id && apiSpec.spec2_id == spec2.id
              }
            )
            return {
              spec1: spec1.name,
              spec2: spec2.name,
              add_price: Number(apiData?.add_price) || 0,
              sku: apiData?.sku || ''
            }
          })
        }))
      } else {
        generate_spec_price = [{}].map(() => ({
          groupName: this.specs.find(item => item.level === 1).level_name,
          groupItems: level1Specs.map(spec1 => {
            const apiData = this.spec_price.find(
              apiSpec => apiSpec.spec1_id == spec1.id && !apiSpec.spec2_id
            )
            return {
              spec1: spec1.name,
              add_price: Number(apiData?.add_price) || 0,
              sku: apiData?.sku || ''
            }
          })
        }))
      }
      this.$emit('updateSpecPrice', generate_spec_price)
    }
  }
};
</script>

<style scoped>
h1 {
  color: #42b983;
}
</style>
